<template>
  <div>
    <custom-toolbar
      :title="'Notifications'"
      :total="$store.state.notification.total_all_notification"
      :notification="true"
      @refresh="getList"
      @filterList="filterList"
    />
    <v-card
      v-scroll.self="onScroll"
      class="mt-2 mx-auto overflow-y-auto"
      max-height="500"
      max-width="650"
    >
      <v-list>
        <template v-for="(notification, index) in $store.state.notification.allItems">
          <v-list-item
            :key="index"
            three-line
            link
            :style="notification.read_at !== null ? 'background-color: rgb(54 185 88 / 1%)' :
              'background-color: rgb(54 185 88 / 9%)'"
            @click="getNotification(notification)"
          >
            <v-list-item-icon>
              <v-icon
                v-if="notification.model_type === 'App\\Models\\User'"
                v-text="icons.mdiCardAccountDetails"
              ></v-icon>
              <v-icon
                v-else-if="notification.model_type === 'App\\Models\\Road'"
                v-text="icons.mdiRoad"
              ></v-icon>
              <v-icon
                v-else-if="notification.model_type === 'App\\Models\\Recovery'"
                v-text="icons.mdiTicketAccount"
              ></v-icon>
              <v-icon
                v-else
                v-text="icons.mdiCash"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ notification.data.title }}</v-list-item-title>
              <v-list-item-subtitle> {{ notification.data.message }}</v-list-item-subtitle>
              <v-list-item-subtitle> Lu: {{ formatdate(notification.read_at) }}</v-list-item-subtitle>
            </v-list-item-content>
            <!-- Item Action/Time -->
            <v-list-item-action>
              <span class="text--secondary text-xs">{{ formatdate(notification.created_at) }}</span>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-list>
    </v-card>
    <v-progress-linear
      v-show="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api/dist/vue-composition-api'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import { formatDateToMonthShort } from '@core/utils/filter'
import {
  mdiDotsVertical, mdiCardAccountDetails, mdiTicketAccount, mdiArrowLeftBoldCircleOutline,
  mdiCash, mdiRoad
} from '@mdi/js'
import CustomToolbar from '@core/components/toolbar/CustomToolbar'
import store from '@/store'
import router from '@/router'

export default {
  name: 'Notifications',
  components: { CustomToolbar },
  setup() {
    const loading = ref(false)
    const selectedItem = ref(0)
    const formatdate = date => (date || date === undefined ? formatDateToMonthShort(date) : null)
    const scrollInvoked = ref(0)
    const getList = (page = 1) => {
      loading.value = true
      store.dispatch('notification/getAllNotificationsList', { page, field: store.state.notification.field }).then(() => {
        loading.value = false
      }).catch(err => {
        console.log(err)
        notify(false, true, err.response.data.message)
        loading.value = false
      })
    }

    const refreshList = (page = 1) => {
      loading.value = true
      store.dispatch('notification/refreshAllNotificationsList', { page, field: store.state.notification.field }).then(() => {
        loading.value = false
      }).catch(err => {
        console.log(err)
        notify(false, true, err.response.data.message)
        loading.value = false
      })
    }

    const onScroll = event => {
      scrollInvoked.value++
      const { scrollTop, offsetHeight, scrollHeight } = event.target

      if ((scrollTop + offsetHeight) >= scrollHeight) {
        if (store.getters['notification/total_all'] !== store.getters['notification/last_page_all']) getList(store.getters['notification/current_page_all'] + 1)
      }
    }

    onMounted(() => {
      getList()
    })

    const filterList = val => {
      store.state.notification.field.read = val
      setTimeout(() => {
        refreshList()
      }, 1000)
    }

    const getNotification = item => {
      store.dispatch('notification/getNotificationById', item).then(() => {
        switch (item.model_type) {
          case 'App\\Models\\User':
            if (router.currentRoute.name !== 'showuser') {
              router.push({ name: 'showuser', params: { id: item.model_id } })
            } else if (router.currentRoute.params.id !== item.model_id) {
              router.replace({ name: 'showuser', params: { id: item.model_id } })
              store.commit('user/INCREMENT_REFRESH_USER_COUNT')
              store.commit('user/SET_REFRESH_USER_ID', item.model_id)
            }
            break
          case 'App\\Models\\Recovery':
            if (router.currentRoute.name !== 'showrecovery') {
              router.push({ name: 'showrecovery', params: { id: item.model_id } })
            } else if (router.currentRoute.params.id !== item.model_id) {
              router.replace({ name: 'showrecovery', params: { id: item.model_id } })
              store.commit('recovery/INCREMENT_REFRESH_ITEM_COUNT')
              store.commit('recovery/SET_REFRESH_ITEM_ID', item.model_id)
            }
            break
          case 'App\\Models\\Payment':
            if (router.currentRoute.name !== 'showpayment') {
              router.push({ name: 'showpayment', params: { id: item.model_id } })
            } else if (router.currentRoute.params.id !== item.model_id) {
              router.replace({ name: 'showpayment', params: { id: item.model_id } })
              store.commit('payment/INCREMENT_REFRESH_PAY_COUNT')
              store.commit('payment/SET_REFRESH_PAY_ID', item.model_id)
            }
            break
          case 'App\\Models\\Road':
            if (router.currentRoute.name !== 'showroad') {
              router.push({ name: 'showroad', params: { id: item.model_id } })
            } else if (router.currentRoute.params.id !== item.model_id) {
              router.replace({ name: 'showroad', params: { id: item.model_id } })
              store.commit('road/INCREMENT_REFRESH_ROAD_COUNT')
              store.commit('road/SET_REFRESH_ROAD_ID', item.model_id)
            }
            break
          default:
            console.log('Nothing')
        }
      }).catch(error => {
        console.log(error)
      })
    }

    const getBack = () => {
      router.back()
    }

    watch(() => store.state.user.lang, lang => {
      refreshList(store.getters['notification/current_page'])
    })

    return {
      getNotification,
      getBack,
      filterList,
      onScroll,
      formatdate,
      scrollInvoked,
      loading,
      selectedItem,
      getList,
      icons: {
        mdiDotsVertical,
        mdiCardAccountDetails,
        mdiTicketAccount,
        mdiArrowLeftBoldCircleOutline,
        mdiCash,
        mdiRoad
      },
    }
  },

}
</script>

<style scoped>

</style>
